<template lang="pug">
include ../../../configs/template
div.row
  div.col-12
    +select-validation('data.comments.additional_info.reason', 'rejectionReasons', 'rejectionReasonStatement', '"name_ukr"', '["required"]')
  div.col-12
    +textarea-validation('data.comments.comment', 'comment', '["required", "alphaUAWithSpecialCharacters"]')
  div.col-12
    v-btn(color="error" @click="setReject" :loading="isLoading") {{$t('setReject')}}
</template>
<script>
import { RejectFormSailorPassport } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'

export default {
  data () {
    return {
      data: {
        ...this.$route.params,
        comments: {
          comment: null,
          additional_info: {
            reason: null,
            cancel_reason: true
          }
        },
        body: {
          status_document: STATUSES_STATEMENT_CONSTANTS.SAILOR_PASSPORT.INVALID
        }
      },
      isLoading: false
    }
  },
  validations () { return { data: RejectFormSailorPassport() } },
  computed: {
    ...mapState({
      rejectionReasons: state => state.directory.rejectionReasons.filter(item => item.model_name !== 'lineinservicerecord')
    })
  },
  methods: {
    ...mapActions(['updateSailorPassportById']),
    async setReject () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('rejectingConfirmation'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.updateSailorPassportById(this.data)
          if (SUCCESS_CODE.includes(response.code)) {
            this.$parent.$parent.$parent.getData()
          }
          this.$v.$reset()
          this.isLoading = false
        }
      })
    }
  }
}
</script>
